import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { trackPageView, trackEvent } from '../analytics';

const COLLECTIONS_SPREADSHEET_ID = '18t_JJyzwhL9FeFk9hnz_7JqZIa_HD3PSYbeYzCKoTuI';
const SUBJECTS_SPREADSHEET_ID = '1Ar7X_3tDUI0v27VAWwVPT1QusBQaE1EE2i9rZajAvw8';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';

const FeaturedCollections = () => {
  const [featuredCollections, setFeaturedCollections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const [collectionsResponse, subjectsResponse] = await Promise.all([
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${COLLECTIONS_SPREADSHEET_ID}/values/Sheet1!A2:F?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`)
      ]);

      const collections = collectionsResponse.data.values.map(([id, subjectId, title, description, thumbnail, featured]) => ({
        id, subjectId, title, description, thumbnail, featured: featured === 'TRUE'
      }));

      setFeaturedCollections(collections.filter(c => c.featured));

      setSubjects(subjectsResponse.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      })));

      setLoading(false);

      trackEvent("featured_collections_loaded", {
        collectionsCount: collections.filter(c => c.featured).length,
        subjectsCount: subjectsResponse.data.values.length
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load featured collections. Please try again later.');
      setLoading(false);

      trackEvent("featured_collections_load_error", {
        error: err.message
      });
    }
  };

  useEffect(() => {
    fetchData();
    
    trackPageView("/featured-collections");
  }, []);

  const handleCollectionClick = useCallback((collection, subject) => {
    trackEvent("featured_collection_click", {
      collectionId: collection.id,
      collectionTitle: collection.title,
      subjectId: subject.id,
      subjectName: subject.name
    });

    navigate(`/worksheets/${subject.id}/${collection.id}`);
  }, [navigate]);

  if (loading) return <div className="text-center py-8">Loading featured collections...</div>;
  if (error) return <div className="text-center py-8 text-red-500">Error: {error}</div>;

  return (
    <div className="bg-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {featuredCollections.map(collection => {
            const subject = subjects.find(s => s.id === collection.subjectId);
            if (!subject) {
              console.error('Subject not found for collection:', collection);
              return null;
            }
            return (
              <div 
                key={collection.id}
                onClick={() => handleCollectionClick(collection, subject)}
                className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer border border-ng-100"
              >
                <div className="w-full aspect-[4/3] overflow-hidden">
                  {collection.thumbnail && (
                    <img
                      src={collection.thumbnail}
                      alt={collection.title || 'Collection thumbnail'}
                      className="w-full h-full object-contain transition-transform duration-300 hover:scale-110"
                      onLoad={() => trackEvent("collection_thumbnail_loaded", {
                        collectionId: collection.id,
                        collectionTitle: collection.title
                      })}
                      onError={() => trackEvent("collection_thumbnail_error", {
                        collectionId: collection.id,
                        collectionTitle: collection.title
                      })}
                    />
                  )}
                </div>
                <div className="p-4 flex flex-col flex-grow">
                  <h3 className="text-xl font-semibold mb-2">{collection.title || 'Untitled Collection'}</h3>
                  <p className="text-gray-600 flex-grow">{collection.description || 'No description available'}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCollections;