import React from 'react';
import { Link } from 'react-router-dom';

const InterlinkSection = () => {
  const worksheetCategories = [
    {
      name: "Mathematics",
      worksheets: [
        { title: "Circle Tracing", url: "/worksheets/maths/circle-tracing-worksheet" },
        { title: "Triangle Tracing", url: "/worksheets/maths/triangle-tracing-worksheet" },
        { title: "Square Tracing", url: "/worksheets/maths/square-tracing-worksheet" },
        { title: "Missing Number", url: "/worksheets/maths/missing-number-worksheet" },
        { title: "Multi-Digit Multiplication", url: "/worksheets/maths/multi-digit-multiplication-worksheets" },
        { title: "Addition", url: "/worksheets/maths/addition-worksheet" },
        { title: "Doubles Addition", url: "/worksheets/maths/doubles-addition-worksheet" },
        { title: "Subtraction", url: "/worksheets/maths/subtraction-worksheet" }
      ]
    },
    {
      name: "Arts and Crafts",
      worksheets: [
        { title: "Number Coloring Pages", url: "/worksheets/arts/number-coloring-pages" },
        { title: "Animal Coloring Pages", url: "/worksheets/arts/animal-coloring-pages" },
        { title: "Vehicle Coloring Pages", url: "/worksheets/arts/vehicle-coloring-pages" },
        { title: "Connect the Dots", url: "/worksheets/arts/connect-the-dots-printable" },
        { title: "Merry Christmas Coloring Pages", url: "/worksheets/arts/merry-christmas-coloring-pages" }
      ]
    },
    {
      name: "Life Skills",
      worksheets: [
        { title: "All About Me", url: "/worksheets/life-skills/all-about-me-worksheet" },
        { title: "Back to School", url: "/worksheets/life-skills/back-to-school-worksheet" },
        { title: "Potty Training Chart", url: "/worksheets/life-skills/potty-training-chart-printable" }
      ]
    },
    {
      name: "English",
      worksheets: [
        { title: "Letter Worksheets", url: "/worksheets/english/letter-worksheets" }
      ]
    }
  ];

  return (
    <section className="mt-12 bg-gray-100 p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Explore More Worksheets</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {worksheetCategories.map((category, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-3">{category.name}</h3>
            <ul className="space-y-2">
              {category.worksheets.map((worksheet, wIndex) => (
                <li key={wIndex}>
                  <Link
                    to={worksheet.url}
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    {worksheet.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default InterlinkSection;