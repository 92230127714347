import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../components/Layout';
import SEO from '../config/seo';
import WorksheetForm from '../components/WorksheetForm';
import EditWorksheet from '../components/EditWorksheet';
import { generateWorksheet, generateWorksheetBatch, uploadToCloudinary } from '../config/api';
import html2pdf from 'html2pdf.js';
import { Loader2, ArrowDownToLine } from 'lucide-react';
import { trackPageView, trackEvent } from '../analytics';
import CustomGoogleAd from '../components/CustomGoogleAd';

const WorksheetGenerator = () => {
  const location = useLocation();
  const [worksheetData, setWorksheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(location.state?.formData || null);
  const [worksheetTitle, setWorksheetTitle] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [includeTitle, setIncludeTitle] = useState(true);
  const [includeNameDate, setIncludeNameDate] = useState(true);
  const [generationProgress, setGenerationProgress] = useState(0);
  const worksheetRef = useRef(null);
  const answerKeyRef = useRef(null);

  useEffect(() => {
    trackPageView("/worksheet-generator");
  }, []);

  const handleGenerateWorksheet = useCallback(async (data) => {
    console.log('Generating worksheet with data:', data);
    setLoading(true);
    setError(null);
    setGenerationProgress(0);
    const startTime = Date.now();

    try {
      let result;
      const totalQuestions = data.numberOfQuestions;

      if (totalQuestions <= 8) {
        // Use the original method for 8 or fewer questions
        result = await generateWorksheet(data);
      } else {
        // Use batching for more than 9 questions
        let allQuestions = [];
        let answerKey = {};
        const batchSize = 5; // Adjust this value based on what works best

        for (let i = 0; i < totalQuestions; i += batchSize) {
          const batch = await generateWorksheetBatch(data, i, Math.min(batchSize, totalQuestions - i));
          allQuestions = [...allQuestions, ...batch.questions];
          answerKey = { ...answerKey, ...batch.answerKey };
          setGenerationProgress(Math.min(100, (i + batchSize) / totalQuestions * 100));
        }

        result = {
          title: `${data.gradeLevel} ${data.topic} Worksheet`,
          questions: allQuestions,
          answerKey: answerKey,
        };
      }

      console.log('Worksheet generated:', result);

      if (!result.questions || !Array.isArray(result.questions)) {
        throw new Error('Invalid worksheet data: questions array is missing or not an array');
      }

      result.questions = result.questions.map(question => ({
        ...question,
        options: question.options || {}
      }));

      result.answerKey = typeof result.answerKey === 'object' ? result.answerKey : {};

      setWorksheetData(result);
      setWorksheetTitle(result.title || 'Untitled Worksheet');

      const duration = Date.now() - startTime;
      trackEvent("worksheet_generated", {
        title: result.title,
        duration: duration,
        ...data
      });
    } catch (err) {
      console.error('Error generating worksheet:', err);
      setError('Failed to generate worksheet. Please try again.');
      trackEvent("worksheet_generation_error", {
        error: err.message,
        ...data
      });
    } finally {
      setLoading(false);
      setGenerationProgress(0);
    }
  }, []);

  useEffect(() => {
    if (formData) {
      handleGenerateWorksheet(formData);
    }
  }, [formData, handleGenerateWorksheet]);

  const generatePdf = useCallback((element, filename) => {
    console.log(`Starting PDF generation process for ${filename}...`);
    return new Promise((resolve, reject) => {
      if (!element) {
        console.error('Element not found');
        reject(new Error('Element not found'));
        return;
      }

      const opt = {
        margin: [15, 15, 25, 15], // Increase bottom margin (third value)
        filename: filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.page-break' }
      };

      html2pdf().set(opt).from(element).outputPdf('blob').then((pdfBlob) => {
        console.log('PDF generation completed successfully');
        resolve(pdfBlob);
      }).catch(error => {
        console.error('Error in html2pdf process:', error);
        reject(error);
      });
    });
  }, []);
// ... (rest of the code remains unchanged)

const saveToCloudinary = useCallback(async (pdfBlob, title) => {
  console.log('Starting to save worksheet to Cloudinary...');
  try {
    const result = await uploadToCloudinary(pdfBlob, title);
    console.log('File successfully saved to Cloudinary. File details:', result);
    trackEvent("cloudinary_upload_success", { title: title, url: result.url });
  } catch (error) {
    console.error('Error saving to Cloudinary:', error);
    trackEvent("cloudinary_upload_error", { title: title, error: error.message });
  }
}, []);

const downloadPdf = useCallback((pdfBlob, filename) => {
  const url = URL.createObjectURL(pdfBlob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}, []);

const handleDownloadWorksheet = useCallback(async () => {
  console.log('Starting worksheet download process...');
  try {
    const pdfBlob = await generatePdf(worksheetRef.current, `${worksheetTitle}.pdf`);
    downloadPdf(pdfBlob, `${worksheetTitle}.pdf`);
    trackEvent("worksheet_pdf_downloaded", { title: worksheetTitle });
    
    // Save to Cloudinary in the background
    saveToCloudinary(pdfBlob, worksheetTitle).catch(console.error);
  } catch (error) {
    console.error('Error in worksheet download process:', error);
    setError('Failed to download worksheet. Please try again.');
    trackEvent("worksheet_download_error", { title: worksheetTitle, error: error.message });
  }
}, [generatePdf, downloadPdf, saveToCloudinary, worksheetTitle]);

const handleDownloadAnswerKey = useCallback(async () => {
  console.log('Starting answer key download process...');
  try {
    const pdfBlob = await generatePdf(answerKeyRef.current, `${worksheetTitle}_AnswerKey.pdf`);
    downloadPdf(pdfBlob, `${worksheetTitle}_AnswerKey.pdf`);
    trackEvent("answer_key_pdf_downloaded", { title: worksheetTitle });
  } catch (error) {
    console.error('Error in answer key download process:', error);
    setError('Failed to download answer key. Please try again.');
    trackEvent("answer_key_download_error", { title: worksheetTitle, error: error.message });
  }
}, [generatePdf, downloadPdf, worksheetTitle]);

const handleFormSubmit = useCallback((newFormData) => {
  console.log('Form submitted with data:', newFormData);
  setFormData(newFormData);
  handleGenerateWorksheet(newFormData);

  trackEvent("worksheet_form_submitted", newFormData);
}, [handleGenerateWorksheet]);

const handleTitleChange = useCallback((newTitle) => {
  setWorksheetTitle(newTitle);
  trackEvent("worksheet_title_changed", { newTitle: newTitle });
}, []);

const handleFontSizeChange = useCallback((change) => {
  setFontSize(prevSize => {
    const newSize = Math.max(12, Math.min(24, prevSize + change));
    trackEvent("font_size_changed", { newSize: newSize, change: change });
    return newSize;
  });
}, []);

const handleIncludeTitleChange = useCallback((checked) => {
  setIncludeTitle(checked);
  trackEvent("include_title_changed", { included: checked });
}, []);

const handleIncludeNameDateChange = useCallback((checked) => {
  setIncludeNameDate(checked);
  trackEvent("include_name_date_changed", { included: checked });
}, []);

const handleBundleClick = useCallback(() => {
  trackEvent('worksheet-bundle-clicked-WG', {
    page: 'worksheet-generator',
    source: 'details_section'
  });
  window.open('https://worksheetmaster3.gumroad.com/l/worksheet-bundle', '_blank');
}, []);

return (
  <Layout>
    <SEO
      title="Custom Worksheet Generator | Get Worksheet"
      description="Create custom worksheets tailored to your needs with our free worksheet generator."
      image="https://getworksheet.co/Worksheet-Generator.png"
      url="https://getworksheet.co/worksheet-generator"
    />
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Worksheet Generator</h1>

      <div className="flex flex-col lg:flex-row gap-4 sm:gap-8">
        <div className="w-full lg:w-1/3 space-y-4">
          <div className="bg-white shadow-md rounded px-4 sm:px-8 py-4 sm:py-6">
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Worksheet Details</h2>
            <WorksheetForm
              onSubmit={handleFormSubmit}
              initialData={formData}
              isLoading={loading}
            />
          </div>

          {/* New Bundle Promotion Component */}
          {/* <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-md rounded px-4 sm:px-8 py-4 sm:py-6">
            <h2 className="text-lg sm:text-xl font-semibold mb-3">Special Offer!</h2>
            <p className="mb-4">88 curated worksheets up for grabs - Just 2$</p>
            <button
              onClick={handleBundleClick}
              className="flex items-center justify-center w-full px-4 py-2 bg-white text-blue-600 rounded-lg shadow-xs hover:bg-gray-100 transition-colors duration-300"
            >
              <ArrowDownToLine className="w-5 h-5 mr-2" />
              <span>Click here</span>
            </button>
          </div> */}

<div className="mt-8">
        <CustomGoogleAd />
      </div>

          {worksheetData && !loading && (
            <EditWorksheet
              initialTitle={worksheetTitle}
              onTitleChange={handleTitleChange}
              onFontSizeChange={handleFontSizeChange}
              fontSize={fontSize}
              includeTitle={includeTitle}
              onIncludeTitleChange={handleIncludeTitleChange}
              includeNameDate={includeNameDate}
              onIncludeNameDateChange={handleIncludeNameDateChange}
            />
          )}
        </div>

        <div className="w-full lg:w-2/3">
          {worksheetData && !loading && (
            <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-0">{worksheetTitle}</h2>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                <button
                  onClick={handleDownloadWorksheet}
                  className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 w-full sm:w-auto"
                >
                  Download Worksheet
                </button>
                <button
                  onClick={handleDownloadAnswerKey}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 w-full sm:w-auto"
                >
                  Download Answer Key
                </button>
              </div>
            </div>
          )}

          {loading && (
            <div className="flex flex-col items-center justify-center h-64">
              <Loader2 className="w-12 h-12 sm:w-16 sm:h-16 text-blue-500 animate-spin" />
              <p className="mt-4 text-base sm:text-lg font-semibold text-gray-700">Generating your worksheet...</p>
              <p className="mt-2 text-sm text-gray-500">This may take a few moments</p>
              {generationProgress > 0 && (
                <>
                  <div className="w-full max-w-md bg-gray-200 rounded-full h-2.5 mt-4">
                    <div className="bg-indigo-600 h-2.5 rounded-full" style={{width: `${generationProgress}%`}}></div>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">{Math.round(generationProgress)}% complete</p>
                </>
              )}
            </div>
          )}

          {error && (
            <div className="bg-white shadow-md rounded px-4 sm:px-8 py-4 sm:py-6 mb-4">
              <p className="text-red-500 text-center">{error}</p>
              <button 
                onClick={() => handleGenerateWorksheet(formData)} 
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 w-full sm:w-auto"
              >
                Retry
              </button>
            </div>
          )}

          {worksheetData && !loading && (
            <div className="bg-white shadow-md rounded px-4 sm:px-8 py-4 sm:py-6 mb-4">
              <div ref={worksheetRef} style={{ fontSize: `${fontSize}px` }} className="overflow-hidden">
                {includeTitle && <h1 className="text-xl sm:text-2xl font-bold mb-4">{worksheetTitle}</h1>}
                {includeNameDate && (
                  <div className="flex flex-col sm:flex-row justify-between mb-4">
                    <div className="mb-2 sm:mb-0">Name: ____________________</div>
                    <div>Date: ____________________</div>
                  </div>
                )}
                <div className="space-y-8">
                  {worksheetData.questions.map((question, index) => (
                    <div key={question.id || index} className="mb-4">
                      <div className="flex items-start mb-3">
                        <span className="mr-2 font-bold">{index + 1}.</span>
                        <span className="font-semibold">{question.question}</span>
                      </div>
                      {question.options && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3 pl-6">
                          {Object.entries(question.options).map(([key, value]) => (
                            <div key={key} className="flex items-center">
                              <span className="mr-2 w-4">{key})</span>
                              <span>{value}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Separate, hidden answer key for PDF generation */}
          <div className="hidden">
            <div ref={answerKeyRef} style={{ fontSize: `${fontSize}px` }} className="overflow-hidden">
              <h1 className="text-xl sm:text-2xl font-bold mb-4">{worksheetTitle} - Answer Key</h1>
              <div className="space-y-2">
                {worksheetData?.answerKey && typeof worksheetData.answerKey === 'object' ? (
                  Object.entries(worksheetData.answerKey).map(([questionNumber, answer]) => (
                    <div key={questionNumber} className="flex items-center">
                      <span className="mr-2 font-bold">{questionNumber}.</span>
                      <span>{answer}</span>
                    </div>
                  ))
                ) : (
                  <p className="text-red-500">Answer key not available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
};

export default WorksheetGenerator;